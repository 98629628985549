import axios from 'axios'
import {Toast, Dialog} from "vant";
import common from '../assets/js/common';
import Qs from 'qs';


axios.defaults.timeout = 10000;                        //响应时间1
let baseUrl = '';
const isPro = Object.is(process.env.NODE_ENV, 'production')
let wx_url = '/h5/#/login'  //登陆页链接
let xiangmu = ''
if(isPro){
    baseUrl = 'https://web.88xpj88.com/' //线上接口地址
}
else{
    baseUrl = 'https://web.88xpj88.com/'  //本地地址
}
let protocol = window.location.protocol; //协议
let host = window.location.host; //主机
baseUrl = protocol + '//www.88xpj88.com/';
// axios.defaults.baseURL = baseUrl;

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    //携带请求头
    let token = window.localStorage.getItem("vuex")
	let lang = '';
    // //下面两种方式都行
    // // config.headers.accessToken = token;hasOwnProperty
    if(token == null){
        token = ''
		lang = 'zh_cn'
    }
    else{
        token = JSON.parse(token).token
        let url = window.location.href
        if(token == '' && url.indexOf('login') == -1 && url.indexOf('zhuce') == -1){
            window.location.href = '/h5/#/login'
        }
		lang = JSON.parse( window.localStorage.getItem("vuex")).i18n;
		if(lang == 'zh'){
			lang = 'zh_cn'
		}else if(lang == 'zhHK'){
			lang = 'ft'
		}
    }
    config.headers['token'] = token
	config.headers['lang'] = lang
    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    Toast('网络异常');
    return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, params,loading=true) {
	if(loading){
		common.toast_loading();
	}
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, Qs.stringify(params),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if(response.data.status.errorCode == 0){
                    resolve(response.data)
                    common.toast_clear()
                }
                else{
                    if(response.data.status.errorCode == 401){
                        window.location.href = wx_url
                    }
                    Toast(response.data.status.msg);
                    setTimeout(() => {
                        common.toast_clear()
                        // resolve(response.data)
                    },1500)
                }

            }, err => {
                reject(err);
                common.toast_clear();
            })
            .catch((error) => {
                reject(error);
                common.toast_clear();
            })
    })
}
///返回一个Promise(发送get请求)
export function fetchGet(url, param,loading=true) {
	if(loading){
		common.toast_loading();
	}

    return new Promise((resolve, reject) => {
        axios.get(baseUrl + url, {params: param},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if(response.data.status.errorCode == 0){
                    resolve(response.data)
                }
                else{
                    if(response.data.status.errorCode == 401){
						Toast('登录失效或异地登录');
						setTimeout(function(){
							window.location.href = wx_url
						},2000)
                        return ;
                    }

                    Toast(response.data.status.msg);
                    return;
                }
                common.toast_clear();
            }, err => {
                reject(err);
                common.toast_clear();
            })
            .catch((error) => {
                reject(error);
                common.toast_clear();
            })
    })
}
export function fetchGet_chonghui(url, param,loading=true) {
	if(loading){
		common.toast_loading();
	}
    return new Promise((resolve, reject) => {
        axios.get(baseUrl + url, {params: param},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if(response.data.Code == 0){
                    resolve(response.data)
                }
                else{
                    if(response.data.Code == 401){
                        Toast('登录失效或异地登录');
                        setTimeout(function(){
                        	window.location.href = wx_url
                        },2000)
						return;
                    }

                    Toast(response.data.status.msg);
                    return;
                }
                common.toast_clear();
            }, err => {
                reject(err);
                common.toast_clear();
            })
            .catch((error) => {
                reject(error);
                common.toast_clear();
            })
    })
}
///返回一个Promise(发送post from_data)
export function fetchPost_formdata(url, param) {
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, param,{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                if(response.data.status.errorCode == 0){
                    resolve(response.data)
                }
                else{
                    Toast(response.data.status.msg);
                    return;
                }
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function moreHttp(option) {
    let arr = [],
        keys = [];
    for (let key in option) {
        keys.push(key);
        arr.push(option[key]);
    }
    return Axios.all(arr).then(
        Axios.spread(function () {
            let result = {};
            for (let i = 0; i < arguments.length; i++) {
                let item = arguments[i];
                if (item) {
                    if (item.data && item.data.data) {
                        result[keys[i]] = item.data.data;
                    } else {
                        result[keys[i]] = item;
                    }
                }
            }
            return result;
        })
    );
}
///返回一个Promise(发送get请求)
export function fetchGet_hk(url, param,loading=true) {

    if(loading){
        common.toast_loading();
    }

    return new Promise((resolve, reject) => {
        axios.get(url, {params: param},{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {

                // if(response.data.status.errorCode == 0){
                //     resolve(response.data)
                // }
                // else{
                resolve(response.data)
                // }
                common.toast_clear();
            }, err => {
                reject(err);
                common.toast_clear();
            })
            .catch((error) => {
                reject(error);
                common.toast_clear();
            })
    })
}
export default {
    fetchPost,
    fetchGet,
    fetchPost_formdata,
    moreHttp,
    wx_url,
    xiangmu,
    baseUrl,
    fetchGet_chonghui,
    fetchGet_hk
}
