import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: 'h_title.shouye',
      keepAlive: false
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/huodong',
    name: 'huodong',
    meta: {
      title: 'h_title.hudong',
      keepAlive: false
    },
    component: () => import('@/views/huodong.vue')
  },
  {
    path: '/fund',
    name: 'fund',
    meta: {
      title: 'h_title.fund',
      keepAlive: false
    },
    component: () => import('@/views/fund.vue')
  },
  {
    path: '/fundlist',
    name: 'fundlist',
    meta: {
      title: 'h_title.fundlist',
      keepAlive: false
    },
    component: () => import('@/views/fundlist.vue')
  },
  {
    path: '/chongzhi',
    name: 'chongzhi',
    meta: {
      title: 'h_title.chongzhi',
      keepAlive: false
    },
    component: () => import('@/views/chongzhi.vue')
  },
  {
    path: '/tixian',
    name: 'tixian',
    meta: {
      title: 'h_title.tixian',
      keepAlive: false
    },
    component: () => import('@/views/tixian.vue')
  },
  {
    path: '/erduzhuanhuan',
    name: 'erduzhuanhuan',
    meta: {
      title: 'h_title.erduzhuanhuan',
      keepAlive: false
    },
    component: () => import('@/views/erduzhuanhuan.vue')
  },
  {
    path: '/gerenzhongxin',
    name: 'gerenzhongxin',
    meta: {
      title: 'h_title.gerenzhongxin',
      keepAlive: false
    },
    component: () => import('@/views/gerenzhongxin.vue')
  },
  {
    path: '/gerenxinxi',
    name: 'gerenxinxi',
    meta: {
      title: 'h_title.gerenxinxi',
      keepAlive: false
    },
    component: () => import('@/views/gerenxinxi.vue')
  },
  {
    path: '/bangdingshouji',
    name: 'bangdingshouji',
    meta: {
      title: 'h_title.bangdingshouji',
      keepAlive: false
    },
    component: () => import('@/views/bangdingshouji.vue')
  },
  {
    path: '/dailizhongxin',
    name: 'dailizhongxin',
    meta: {
      title: 'h_title.dailizhongxin',
      keepAlive: false
    },
    component: () => import('@/views/dailizhongxin.vue')
  },

  {
    path: '/yongjinfafang',
    name: 'yongjinfafang',
    meta: {
      title: 'dailizhongxin.yongjinfafang',
      keepAlive: false
    },
    component: () => import('@/views/yongjinfafang.vue')
  },
  {
    path: '/huiyuangcunkuang',
    name: 'huiyuangcunkuang',
    meta: {
      title: 'dailizhongxin.huiyuangcunkuang',
      keepAlive: false
    },
    component: () => import('@/views/huiyuangcunkuang.vue')
  },
  {
    path: '/huiyuangtixian',
    name: 'huiyuangtixian',
    meta: {
      title: 'dailizhongxin.huiyuangtixian',
      keepAlive: false
    },
    component: () => import('@/views/huiyuangtixian.vue')
  },
  {
    path: '/huiyuan',
    name: 'huiyuan',
    meta: {
      title: 'dailizhongxin.huiyuan',
      keepAlive: false
    },
    component: () => import('@/views/huiyuan.vue')
  },
  {
    path: '/huiyuanshuying',
    name: 'huiyuanshuying',
    meta: {
      title: 'dailizhongxin.huiyuanshuying',
      keepAlive: false
    },
    component: () => import('@/views/huiyuanshuying.vue')
  },

  {
    path: '/xiaoxi',
    name: 'xiaoxi',
    meta: {
      title: 'h_title.xiaoxi',
      keepAlive: false
    },
    component: () => import('@/views/xiaoxi.vue')
  },
  {
    path: '/baobiao_cunkuang',
    name: 'baobiao_cunkuang',
    meta: {
      title: 'h_title.baobiao_cunkuang',
      keepAlive: false
    },
    component: () => import('@/views/baobiao_cunkuang.vue')
  },
  {
    path: '/baobiao_tixian',
    name: 'baobiao_tixian',
    meta: {
      title: 'h_title.baobiao_tixian',
      keepAlive: false
    },
    component: () => import('@/views/baobiao_tixian.vue')
  },
  {
    path: '/baobiao_touzhu',
    name: 'baobiao_touzhu',
    meta: {
      title: 'h_title.baobiao_touzhu',
      keepAlive: false
    },
    component: () => import('@/views/baobiao_touzhu.vue')
  },
  {
    path: '/baobiao_fanshui',
    name: 'baobiao_fanshui',
    meta: {
      title: 'h_title.baobiao_fanshui',
      keepAlive: false
    },
    component: () => import('@/views/baobiao_fanshui.vue')
  },
  {
    path: '/baobiao_eduzhuanhuan',
    name: 'baobiao_eduzhuanhuan',
    meta: {
      title: 'h_title.baobiao_eduzhuanhuan',
      keepAlive: false
    },
    component: () => import('@/views/baobiao_eduzhuanhuan.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'h_title.login',
      keepAlive: false
    },
    component: () => import('@/views/login.vue')
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    meta: {
      title: 'h_title.zhuce',
      keepAlive: false
    },
    component: () => import('@/views/zhuce.vue')
  },
  {
    path: '/xiugaimima',
    name: 'xiugaimima',
    meta: {
      title: 'gerenzhongxin.xiugaimima',
      keepAlive: false
    },
    component: () => import('@/views/xiugaimima.vue')
  },
  {
    path: '/xiugaizhifumima',
    name: 'xiugaizhifumima',
    meta: {
      title: 'h_title.xiugaixhifumima',
      keepAlive: false
    },
    component: () => import('@/views/xiugaizhifumima.vue')
  },
  {
    path: '/huodongxiangqing',
    name: 'huodongxiangqing',
    meta: {
      title: 'h_title.huodongxiangqing',
      keepAlive: false
    },
    component: () => import('@/views/huodongxiangqing.vue')
  },
  {
    path: '/zizhufuwu',
    name: 'zizhufuwu',
    meta: {
      title: 'h_title.zizhufuwu',
      keepAlive: false
    },
    component: () => import('@/views/zizhufuwu.vue')
  },
  {
    path: '/zizhufuwu_list',
    name: 'zizhufuwu_list',
    meta: {
      title: 'h_title.zizhufuwu_list',
      keepAlive: false
    },
    component: () => import('@/views/zizhufuwu_list.vue')
  },
  {
    path: '/zizhufuwu_detail',
    name: 'zizhufuwu_detail',
    meta: {
      title: 'h_title.zizhufuwu_list',
      keepAlive: false
    },
    component: () => import('@/views/zizhufuwu_detail.vue')
  },
  {
    path: '/bangdingyinhangka',
    name: 'bangdingyinhangka',
    meta: {
      title: 'h_title.bangdingyihangka',
      keepAlive: false
    },
    component: () => import('@/views/bangdingyinhangka.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
    return {x: 0, y: 0}
  }
})

// router.beforeEach((to, form, next) => { //跳转前回调
//
//   document.title = 'XXXX-' + to.matched[0].meta.title;
//   next()
// })

export default router
